export const scrollToTop = () => {
  setTimeout(() => {
    window.scrollTo({top: 0});
  });
};

export const scrollToBottom = (id) => {
  const container = id ? document.getElementById(id) : window;
  const scrollHeight = id
    ? document.getElementById(id).scrollHeight
    : document.body.scrollHeight;

  setTimeout(() => {
    container.scrollTo(0, scrollHeight);
  });
};

export const scrollToElement = (ref) => {
  document.getElementById('root').scrollTo({
    behavior: 'smooth',
    top: ref.current.getBoundingClientRect().top,
  });
};
